import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../components/page-header'
import PageSubtitle from '../components/page-subtitle'

export default (props) => {
  const {
    pageTitle,
    subtitle: { subtitle },
    certifications,
  } = props.data.allContentfulTemplateCertification.nodes[0]

  const certificates = certifications.map((certificate) => ({
    name: certificate.name,
    image: `https:${certificate.image.file.url}`,
    pdf: `https:${certificate.pdf.file.url}`,
  }))

  return (
    <Layout pageURL={props.uri}>
      <SEO title={pageTitle} />
      <main>
        <PageHeader pageTitle={pageTitle} />
        <section className="container mt-5 mb-4 pb-5">
          <PageSubtitle subtitle={subtitle} />
          <div className="row justify-content-center mt-5">
            {certificates.map(({ name, image, pdf }) => (
              <div className="col-md-3 my-3" key={name}>
                <div className="card">
                  <div className="card-header bg-transparent">{name}</div>
                  <img
                    src={image}
                    className="card-img-top responsive-img px-1"
                    alt={name}
                    style={{ maxHeight: '16rem', maxWidth: '100%' }}
                  />
                  <div className="card-footer text-center">
                    <a
                      href={pdf}
                      className="btn btn-outline font-size-4 text-grey-6"
                      target="_blank"
                      rel="noopener noreferrer">
                      <i className="far fa-file-alt font-size-5 mr-2"></i>
                      View certification
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query CertificationsQuery {
    allContentfulTemplateCertification {
      nodes {
        pageTitle
        subtitle {
          subtitle
        }
        certifications {
          name
          image {
            file {
              url
            }
          }
          pdf {
            file {
              url
            }
          }
        }
      }
    }
  }
`
